import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Fade from 'react-reveal/Fade'
import Badge from '../badge'
import styles from './benefits.module.css'
import runesBg from '../../images/runes.png'

const sectionOneBadges = [
  'officialContent',
  'crossPlatform',
  'healthyCompetition',
]

const sectionSixBadges = [
  'cognitiveBenefits',
  'socioemotionalSkills',
  'teamwork',
  'financialEducation',
  'readingComprehension',
]

const BenefitsSection = () => {
  const { formatMessage } = useIntl()

  const data = useStaticQuery(graphql`
    query {
      officialContent: file(
        relativePath: { eq: "badges/official-content.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      crossPlatform: file(relativePath: { eq: "badges/cross-platform.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      healthyCompetition: file(
        relativePath: { eq: "badges/healthy-competition.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      laptop01: file(relativePath: { eq: "app/laptop-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tablet01: file(relativePath: { eq: "app/tablet-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pc01: file(relativePath: { eq: "app/pc-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tablet02: file(relativePath: { eq: "app/tablet-02.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tablet03: file(relativePath: { eq: "app/tablet-03.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      smartphone01: file(relativePath: { eq: "app/smartphone-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      smartphone02: file(relativePath: { eq: "app/smartphone-02.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      smartphone03: file(relativePath: { eq: "app/smartphone-03.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cognitiveBenefits: file(
        relativePath: { eq: "badges/cognitive-benefits.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      socioemotionalSkills: file(
        relativePath: { eq: "badges/socioemotional-skills.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teamwork: file(relativePath: { eq: "badges/teamwork.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      financialEducation: file(
        relativePath: { eq: "badges/financial-education.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      readingComprehension: file(
        relativePath: { eq: "badges/reading-comprehension.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const renderSectionOneCards = () =>
    sectionOneBadges.map((badge, index) => (
      <div key={badge} className="w-full md:w-1/2 md:mx-auto py-4 md:px-4">
        <Badge>
          <Img fluid={data[badge].childImageSharp.fluid} />
        </Badge>

        <h2 className="subheading text-yellow-dark mt-4 break-words">
          <FormattedMessage id={`benefits.section1.item${index + 1}.title`} />
        </h2>

        <p className="text-white">
          <FormattedMessage
            id={`benefits.section1.item${index + 1}.description`}
          />
        </p>
      </div>
    ))

  const renderSectionSixBadges = () =>
    sectionSixBadges.map((badge, index) => (
      <div key={badge} className="w-full md:w-1/2 lg:w-1/3 p-4 lg:p-6">
        <Badge>
          <Img fluid={data[badge].childImageSharp.fluid} />
        </Badge>

        <h3 className="subheading text-blue-dark mt-6">
          <FormattedMessage id={`benefits.section6.card${index + 1}.title`} />
        </h3>

        <p className="text-blue-dark">
          <FormattedMessage
            id={`benefits.section6.card${index + 1}.description`}
          />
        </p>
      </div>
    ))

  return (
    <section id={formatMessage({ id: 'sections.benefits.id' })}>
      {/* Section 1 */}
      <div className={styles.sectionOne}>
        <div className="custom-container custom-container--large pt-12">
          <div className="px-0 md:px-40 text-center">
            <h3 className="subheading text-blue-dark">
              <FormattedMessage id="sections.benefits.title" />
            </h3>

            <h2 className="heading text-white">
              <FormattedMessage id="benefits.section1.title" />
            </h2>

            <p className="text-white">
              <FormattedMessage id="benefits.section1.p1" />
            </p>

            <p className="mt-4 text-yellow-dark">
              <FormattedMessage id="benefits.section1.p2" />
            </p>
          </div>
        </div>

        <div className="custom-container custom-container--extralarge">
          <div className="mt-8 px-8 md:px-0 flex items-start justify-between flex-wrap">
            {renderSectionOneCards()}
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="py-40 bg-gradient-purple">
        <div className="custom-container custom-container--extralarge relative">
          <div className="w-full md:w-4/5 lg:w-3/5 text-white text-right">
            <h2 className="heading">
              <FormattedMessage id="benefits.section2.title" />
            </h2>

            <p>
              <FormattedMessage id="benefits.section2.description" />
            </p>
          </div>

          <img className={styles.runesRight} alt="Runes" src={runesBg} />

          <Fade bottom delay={300}>
            <div className={styles.s2D1}>
              <Img alt="Laptop" fluid={data.laptop01.childImageSharp.fluid} />
            </div>
          </Fade>

          <Fade bottom delay={800}>
            <div className={styles.s2D2}>
              <Img alt="Tablet" fluid={data.tablet01.childImageSharp.fluid} />
            </div>
          </Fade>
        </div>
      </div>

      {/* Section 3 */}
      <div className="py-40 bg-gradient-green">
        <div className="custom-container custom-container--extralarge relative">
          <div className="w-full md:w-4/5 lg:w-3/5 md:ml-auto text-white text-left">
            <h2 className="heading">
              <FormattedMessage id="benefits.section3.title" />
            </h2>

            <p>
              <FormattedMessage id="benefits.section3.description" />
            </p>
          </div>

          <img className={styles.runesLeft} alt="Runes" src={runesBg} />

          <Fade bottom delay={300}>
            <div className={styles.s3D1}>
              <Img alt="PC" fluid={data.pc01.childImageSharp.fluid} />
            </div>
          </Fade>

          <Fade bottom delay={800}>
            <div className={styles.s3D2}>
              <Img alt="Tablet" fluid={data.tablet02.childImageSharp.fluid} />
            </div>
          </Fade>
        </div>
      </div>

      {/* Section 4 */}
      <div className="py-40 bg-gradient-orange">
        <div className="custom-container custom-container--extralarge relative">
          <div className="w-full md:w-4/5 lg:w-3/5 text-white text-right">
            <h2 className="heading">
              <FormattedMessage id="benefits.section4.title" />
            </h2>

            <p>
              <FormattedMessage id="benefits.section4.description" />
            </p>
          </div>

          <img className={styles.runesRight} alt="Runes" src={runesBg} />

          <Fade bottom delay={300}>
            <div className={styles.s4D1}>
              <Img alt="Tablet" fluid={data.tablet03.childImageSharp.fluid} />
            </div>
          </Fade>

          <Fade bottom delay={800}>
            <div className={styles.s4D2}>
              <Img
                alt="Smartphone"
                fluid={data.smartphone01.childImageSharp.fluid}
              />
            </div>
          </Fade>
        </div>
      </div>

      {/* Section 5 */}
      <div className="py-48 bg-gradient-blue">
        <div className="custom-container custom-container--extralarge relative">
          <div className="w-full md:w-4/5 lg:w-3/5 ml-auto text-left text-white">
            <h2 className="heading">
              <FormattedMessage id="benefits.section5.title" />
            </h2>

            <p>
              <FormattedMessage id="benefits.section5.description" />
            </p>
          </div>

          <img className={styles.runesLeft} alt="Runes" src={runesBg} />

          <Fade bottom delay={300}>
            <div className={styles.s5D1}>
              <Img
                alt="Smartphone"
                fluid={data.smartphone02.childImageSharp.fluid}
              />
            </div>
          </Fade>

          <Fade bottom delay={800}>
            <div className={styles.s5D2}>
              <Img
                alt="Smartphone"
                fluid={data.smartphone03.childImageSharp.fluid}
              />
            </div>
          </Fade>
        </div>
      </div>

      {/* Section 6 */}
      <div className={styles.sectionSix}>
        <div className="custom-container">
          <div className="w-4/5 md:w-3/5 mx-auto">
            <h2 className="heading text-blue-dark">
              <FormattedMessage id="benefits.section6.title" />
            </h2>

            <p className="text-blue-dark">
              <FormattedMessage id="benefits.section6.description" />
            </p>
          </div>
        </div>

        <div className="custom-container custom-container--extralarge mt-12">
          <div className="flex justify-center items-start flex-wrap">
            {renderSectionSixBadges()}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BenefitsSection
