import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Slider from 'react-slick'
import CarouselPrevArrow from '../carousel/prev-arrow'
import CarouselNextArrow from '../carousel/next-arrow'
import 'slick-carousel/slick/slick.css'

const testimonials = [
  { key: 'margarita', alt: 'Margarita Espinoza' },
  { key: 'mario', alt: 'Mario Herrera' },
  { key: 'ana', alt: 'Ana Karla Velazquez' },
  { key: 'roberto', alt: 'Roberto Sabbar' },
  { key: 'luis', alt: 'Luis Daniel M' },
  { key: 'mariana', alt: 'Mariana Carmona' },
]

const TestimonialsSection = () => {
  const { formatMessage } = useIntl()

  const data = useStaticQuery(graphql`
    query {
      margarita: file(
        relativePath: { eq: "testimonials/margarita-espinoza.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mario: file(relativePath: { eq: "testimonials/mario-herrera.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ana: file(relativePath: { eq: "testimonials/ana-karla-velazquez.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      roberto: file(relativePath: { eq: "testimonials/roberto-sabbar.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      luis: file(relativePath: { eq: "testimonials/luis-daniel-m.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mariana: file(relativePath: { eq: "testimonials/mariana-carmona.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const renderTestimonials = () => {
    const elements = []
    for (let i = 1; i <= testimonials.length; i++) {
      elements.push(
        <div key={i} className="p-4">
          <div className="p-4 lg:p-6 bg-blue-lightest text-blue-dark shadow-md">
            <div className="p-4 lg:p-2">
              <Img
                className="w-full h-auto rounded-full overflow-hidden border-4 border-blue-dark"
                alt={testimonials[i - 1].alt}
                fluid={data[testimonials[i - 1].key].childImageSharp.fluid}
                fadeIn={false}
              />
            </div>

            <blockquote className="lg:mt-4">
              <FormattedMessage id={`testimonials.cards.card${i}.text`} />
            </blockquote>

            <div className="font-semibold mt-4">
              <FormattedMessage id={`testimonials.cards.card${i}.user`} />
            </div>

            <div className="text-sm opacity-75">
              (
              <FormattedMessage id={`testimonials.cards.card${i}.occupation`} />
              )
            </div>
          </div>
        </div>
      )
    }
    return elements
  }

  return (
    <section
      id={formatMessage({ id: 'sections.testimonials.id' })}
      className="bg-white"
    >
      <div className="custom-container max-w-5xl pt-20 pb-12">
        <h2 className="subheading text-blue-dark">
          <FormattedMessage id="sections.testimonials.title" />
        </h2>

        <h3 className="heading text-blue-dark">
          <FormattedMessage id="testimonials.title" />
        </h3>

        <div className="px-8">
          <Slider
            infinite={true}
            slidesToShow={3}
            slidesToScroll={1}
            speed={500}
            prevArrow={<CarouselPrevArrow className="bg-purple" />}
            nextArrow={<CarouselNextArrow className="bg-purple" />}
            responsive={[
              {
                breakpoint: 1024,
                settings: {},
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            {renderTestimonials()}
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default TestimonialsSection
