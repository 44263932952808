import React from 'react'
import PropTypes from 'prop-types'

const Badge = ({ label, children }) => {
  return (
    <div className="hover:cursor-pointer">
      <div className="w-32 h-32 mx-auto rounded-full overflow-hidden">
        {children}
      </div>

      {label && (
        <div className="mt-2 text-center text-blue-lighter">{label}</div>
      )}
    </div>
  )
}

Badge.propTypes = {
  label: PropTypes.string,
}

export default Badge
