import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import styles from './contact.module.css'

const ContactSection = () => {
  const { boat } = useStaticQuery(graphql`
    query {
      boat: file(relativePath: { eq: "boat-vikidz.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })

  const { formatMessage } = useIntl()

  const onInputChange = (event, field) => {
    const formDataCopy = { ...formData }
    formDataCopy[field] = event.target.value
    setFormData(formDataCopy)
  }

  return (
    <section
      id={formatMessage({ id: 'sections.contact.id' })}
      className={styles.section}
    >
      <div className="custom-container py-12 relative">
        <div className={styles.boat}>
          <Img alt="Vikidz boat" fluid={boat.childImageSharp.fluid} />
        </div>

        <div className="relative z-20">
          <h2 className="subheading text-blue-dark">
            <FormattedMessage id="sections.contact.title" />
          </h2>

          <h3 className="heading text-blue-dark">
            <FormattedMessage id="contact.title" />
          </h3>

          <p className="text-blue-dark">
            <FormattedMessage id="contact.description" />
          </p>

          <div className="mt-8 md:mt-12 w-full md:w-3/5 text-left">
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />

              <div className="hidden">
                <label>
                  Don’t fill this out if you're human:
                  <input type="hidden" name="bot-field" />
                </label>
              </div>

              <input
                name="name"
                type="text"
                className="form-control"
                placeholder={formatMessage({ id: 'contact.fields.name' })}
                value={formData.name}
                onChange={event => onInputChange(event, 'name')}
              />

              <input
                name="email"
                type="email"
                className="form-control"
                placeholder={formatMessage({ id: 'contact.fields.email' })}
                value={formData.email}
                onChange={event => onInputChange(event, 'email')}
              />

              <textarea
                name="message"
                rows="4"
                className="form-control resize-none"
                placeholder={formatMessage({ id: 'contact.fields.message' })}
                value={formData.message}
                onChange={event => onInputChange(event, 'message')}
              />

              <button className="btn" type="submit">
                <FormattedMessage id="contact.fields.send" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactSection
