import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ExperienceSection from '../components/sections/experience'
import BenefitsSection from '../components/sections/benefits'
import TestimonialsSection from '../components/sections/testimonials'
import ContactSection from '../components/sections/contact'

const IndexPage = () => {
  const { formatMessage } = useIntl()

  return (
    <Layout headerLinkColor="lg:text-purple">
      <SEO
        lang={formatMessage({ id: 'lang' })}
        title={formatMessage({ id: 'title' })}
        description={formatMessage({ id: 'description' })}
      />

      <ExperienceSection />
      <BenefitsSection />
      <TestimonialsSection />
      <ContactSection />
    </Layout>
  )
}

export default IndexPage
