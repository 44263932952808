import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import styles from './experience.module.css'
import hurrayMp4 from '../../videos/hurray-v2.mp4'

const badges = [
  { langKey: 'teacher', link: 'teacher' },
  { langKey: 'school', link: 'school' },
  { langKey: 'parent', link: 'tutor' },
  { langKey: 'student', link: 'student' },
]

const ExperienceSection = () => {
  const { formatMessage } = useIntl()

  const data = useStaticQuery(graphql`
    query {
      vikid: file(relativePath: { eq: "vikid-orange.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vikidz: file(relativePath: { eq: "vikidz-port-boat.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teacherGray: file(relativePath: { eq: "badges/teacher-gray.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teacherColor: file(relativePath: { eq: "badges/teacher-color.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schoolGray: file(relativePath: { eq: "badges/school-gray.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schoolColor: file(relativePath: { eq: "badges/school-color.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      parentGray: file(relativePath: { eq: "badges/parent-gray.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      parentColor: file(relativePath: { eq: "badges/parent-color.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      studentGray: file(relativePath: { eq: "badges/student-gray.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      studentColor: file(relativePath: { eq: "badges/student-color.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const renderBadges = () =>
    badges.map(badge => (
      <div
        key={badge.langKey}
        className="w-full sm:w-1/2 md:w-auto mt-4 md:mt-0"
      >
        <div className={styles.flatBadge}>
          <Link to={`/${badge.link}`}>
            <div className="rounded-full shadow-md inline-block">
              <Img
                fadeIn={false}
                className={styles.imgColor}
                alt={formatMessage({ id: `experience.${badge.langKey}` })}
                fluid={data[`${badge.langKey}Color`].childImageSharp.fluid}
              />

              <Img
                fadeIn={false}
                className={styles.imgGray}
                alt={formatMessage({ id: `experience.${badge.langKey}` })}
                fluid={data[`${badge.langKey}Gray`].childImageSharp.fluid}
              />
            </div>

            <div className="text-blue-dark text-center">
              <FormattedMessage id={`experience.${badge.langKey}`} />
            </div>
          </Link>
        </div>
      </div>
    ))

  return (
    <section
      id={formatMessage({ id: 'sections.experience.id' })}
      className={styles.section}
    >
      <div className="custom-container custom-container--extralarge pt-40 pb-2">
        <div className="flex items-center justify-center">
          <div className="relative z-40 w-full md:w-6/12 lg:w-5/12 text-center">
            <h1 className="heading text-purple">
              <FormattedMessage id="experience.title" />
            </h1>

            <p className="text-gray">
              <FormattedMessage id="experience.description" />
            </p>

            <button className="btn mt-4">
              <FormattedMessage id="experience.button" />
            </button>
          </div>

          <div className="hidden md:block w-5/12 lg:w-6/12 xl:w-7/12 pl-4">
            <video autoPlay loop muted playsInline>
              <source src={hurrayMp4} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>

      <div className="custom-container custom-container--extralarge py-4 relative z-40">
        <div className={styles.badgesWrapper}>{renderBadges()}</div>

        <div className={styles.vikidOrange}>
          <Img alt="Vikid" fluid={data.vikid.childImageSharp.fluid} />
        </div>

        <div className={styles.vikidz}>
          <Img alt="Vikidz" fluid={data.vikidz.childImageSharp.fluid} />
        </div>
      </div>
    </section>
  )
}

export default ExperienceSection
